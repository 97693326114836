body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.slick-track {
  display: flex;
  flex-direction: row;
}
.slick-slide .div .div {
  width: auto;
}
.slick-list {
  max-width: 80%;
  overflow: hidden;
}

@font-face {
  font-family: "Europa-Regular";
  src: url(./assets/fonts/Europa/europa-regular-webfont.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Europa-Light";
  src: url(./assets/fonts/Europa/europa-light-webfont.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Europa-Bold";
  src: url(./assets/fonts/Europa/europa-bold-webfont.ttf);
  font-display: swap;
}
@font-face {
  font-family: "CormorantGaramond-Regular";
  src: url(./assets/fonts/Cormorant/CormorantGaramond-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "CormorantGaramond-BoldItalic";
  src: url(./assets/fonts/Cormorant/CormorantGaramond-BoldItalic.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Muster";
  src: url(./assets/fonts/Muster/Muster.otf);
  font-display: swap;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

#popup-county-preview {
  z-index: 9999;
}
